const kh = {
  IR: 'IR',
  ENT: 'ENT',
  ECG: 'ECG',
  MRI: 'MRI',
  HPV: 'HPV',
  BMD: 'BMD',
  EEG: 'EEG',
  EMG: 'EMG',
  DATE: 'កាលបរិច្ឆេទ',
  NOTE: 'កំណត់ចំណាំ',
  SPO2: 'SPO2',
  FEES: 'FEES',
  X_RAY: 'X-Ray',
  OTHER: 'ផ្សេងៗ',
  PULSE: 'ជីពចរ',
  HEIGHT: 'កម្ពស់',
  WEIGHT: 'ទម្ងន់',
  CT_SCAN: 'CT Scan',
  ABDOMEN: 'ពោះ',
  GLUCOSE: 'គ្លុយកូស',
  VACCINES: 'វ៉ាក់សាំង',
  SYSTOLIC: 'ស៊ីស្តូលិក',
  OPERATION: 'វះកាត់',
  FIBROSCAN: 'Fibroscan',
  ALLERGIES: 'អាឡែស៊ី',
  DIASTOLIC: 'ឌីស្តូលីក',
  PATHOLOGY: 'រោគវិទ្យា',
  VACCINATION: 'ចាក់វ៉ាក់សាំង',
  SPIROMETRY: 'Spirometry',
  EVALUATION: 'វាយតម្លៃ',
  VITAL_SIGN: 'សញ្ញាសំខាន់ៗ',
  ECHOGRAPHY: 'Echography',
  OSTEOSCOPY: 'Osteoscopy',
  COLONOSCOPY: 'ឆ្លុះពោះវៀនធំ',
  TEMPERATURE: 'សីតុណ្ហភាព',
  MEDICATIONS: 'ថ្នាំ',
  MAMMOGRAPHY: 'ម៉ាំម៉ូជី',
  PRESCRIPTION: 'វេជ្ជបញ្ជា',
  CATH_LAB: 'មន្ទីរពិសោធន៍ Cath',
  MEDICAL_SUPPLY: 'ផ្គត់ផ្គង់វេជ្ជសាស្ត្រ',
  RADIOTHERAPY: 'ព្យាបាលដោយកាំរស្មី',
  ADMINISTERED: 'គ្រប់គ្រង',
  ENT_ENDOSCOPY: 'ENT Endoscopy',
  INVESTIGATION: 'ថត | ឆ្លុះ',
  MENTAL_STATUS: 'ស្ថានភាពផ្លូវចិត្ត',
  NEUROSONOLOGY: 'សរសៃប្រសាទ',
  TREATMENT_PLAN: 'ផែនការព្យាបាល',
  SKIN_EXTREMITY: 'Skin Extremity',
  CLINIC_FEATURE: 'មុខងារគ្លីនិក',
  FAMILY_HISTORY: 'ប្រវត្តិ​គ្រួសារ',
  SOCIAL_HISTORY: 'ប្រវត្តិសង្គម',
  PRESENT_ILLNESS: 'ជំងឺបច្ចុប្បន្ន',
  MEDICAL_HISTORY: 'ប្រវត្តិវេជ្ជសាស្ត្រ',
  CHIEF_COMPLAINT: 'កន្លែងដែលធ្វេីទុក',
  RESPIRATORY_RATE: 'អត្រាផ្លូវដង្ហើម',
  CYTOPATHOLOGY_HPV: 'រោគវិទ្យា HPV',
  UROGENITAL_SYSTEM: 'ប្រព័ន្ធ Urogenital',
  REVIEW_OF_SYSTEMS: 'ពិនិត្យឡើងវិញ',
  CURRENT_MEDICATION: 'ថ្នាំព្យាបាលបច្ចុប្បន្ន',
  HISTORY_OF_ILLNESS: 'ប្រវត្តិជំងឺ',
  GENERAL_APPEARANCE: 'រូបរាង​ទូទៅ',
  RESPIRATORY_SYSTEM: 'ប្រព័ន្ធផ្លូវដង្ហើម',
  GLASGOW_COMA_SCALE: 'មាត្រដ្ឋាន Glasgow Coma',
  EVALUATION_SUMMARY: 'សង្ខេបការវាយតម្លៃ',
  OTORHINOLARYNGOLOGY: 'អូតូរីន ​​រោគវិទ្យា',
  VACCINATION_HISTORY: 'ប្រវត្តិនៃការចាក់វ៉ាក់សាំង',
  EVALUATION_CATEGORY: 'ប្រភេទវាយតម្លៃ',
  EVALUATION_DIAGNOSIS: 'វិនិច្ឆ័យវាយតម្លៃ',
  PHYSICAL_EXAMINATION: 'ត្រួតពិនិត្យ​រាងកាយ',
  PAST_MEDICAL_HISTORY: 'ប្រវត្តិវេជ្ជសាស្ត្រកន្លងមក',
  H_PYLORI_BREATH_TEST: 'តេស្តដង្ហើម H-Pylori',
  LABORATORY_TEST_SHEET: 'តែស្តឡាបូ',
  CARDIOVASCULAR_SYSTEM: 'ប្រព័ន្ធ​សរសៃឈាម​បេះដូង',
  CENTRAL_NERVOUS_SYSTEM: 'ប្រព័ន្ធ​សរសៃប្រសាទ​ក​ណ្តា​ល',
  GASTRO_INTESTINAL_SYSTEM: 'ប្រព័ន្ធរំលាយអាហារ - ពោះវៀន',
  PRODUCT_NAME_LOT_NUMBER: 'ឈ្មោះផលិតផល | លេខឡូតិ៍',
  FINAL_DIAGNOSIS_EVALUATION: 'រោគវិនិច្ឆ័យ និងវាយតម្លៃចុងក្រោយ',
  TODAY_APPOINTMENT_LIST: 'បញ្ជីការណាត់ជួបថ្ងៃនេះ',
  APPOINTMENT_TIME: 'ពេលវេលាណាត់ជួប',
  PATIENT: 'អ្នកជំងឺ',
  TOTAL_PATIENT: 'អ្នកជំងឺសរុប',
  CURRENT_PATIENT_IPD_INFORMATION: 'ព័ត៌មានបច្ចុប្បន្នរបស់អ្នកជំងឺ IPD',
  SAVE: 'រក្សាទុក',
  ADMISSION_CODE: 'លេខកូដចូលសំរាកពេទ្យ',
  ADMISSION_DATE: 'ថ្ងៃចូលសំរាកពេទ្យ',
  EXPECTED_DISCHARGE_DATE: 'ការរំពឹងទុកពេលនឹងចាកចេញ',
  DEPARTMENT: 'ដេប៉ាតឺម៉ង់',
  ADMISSION_TYPE: 'ប្រភេទចូលសំរាក',
  ATTENDING_PHYSICIAN: 'គ្រូពេទ្យដែលមេីលខុសត្រូវ',
  NURSE_ASSIGNED: 'គិលានុបដ្ឋាយិកាដែលថែទាំ',
  DIAGNOSIS: 'រោគវិនិច្ឆ័យ',

  // VISIT TABLE
  STATUS: 'ស្ថានភាព',
  BLOOD_PRESSURE: 'សម្ពាធ​ឈាម',
  PHYSICIAN: 'គ្រូពេទ្យ',
  PATIENT_INFORMATION: 'ព័ត៌មានអ្នកជំងឺ',
  PATIENT_QR_CODE: 'QR របស់អ្នកជំងឺ',
  FULLNAME: 'ឈ្មោះ​ពេញ',
  FULLNAME_LATIN: 'ឈ្មោះពេញ (ឡាតាំង)',
  NSSF_NUMBER: 'លេខ ប.ស.ស',
  GENDER: 'ភេទ',
  AGE: 'អាយុ',
  PATIENT_PAYMENT_TYPE: 'ប្រភេទការទូទាត់',
  REFER_DOCTOR: 'យោងវេជ្ជបណ្ឌិត',
  BLOOD_GROUP: 'ប្រភេទឈាម',
  VISIT_INFORMATION: 'ព័ត៌មានព្យាបាល',
  PREVIEW: 'មេីលឡេីងវិញ',

  ADD_NEW: 'បន្ថែម​ថ្មី',
  EDIT: 'កែសម្រួល',
  DELETE: 'លុប',
  EXIPIRED_DATE: 'កាលបរិច្ឆេទ​ផុត​កំណត់',
  UPLOAD_IMAGE: 'បង្ហោះរូបភាព',
  OPEN: 'Open',

  PRICE_DOMESTIC: 'តម្លៃក្នុងស្រុក',
  PRICE_FOREIGNER: 'តម្លៃជនបរទេស',
  PRICE_NSSF: 'តម្លៃ ប.ស.ស',
  PRICE_INSURANCE: 'តម្លៃធានារ៉ាប់',

  // MEDICATION
  PHARMACY: 'ឱសថស្ថាន',
  MEDICATION: 'ថ្នាំ',
  MEDICATION_LIST: 'បញ្ជីថ្នាំ',
  ADD_MEDICAL_FROM_PROVIDER: 'បន្ថែមថ្នាំរពីអ្នកផ្តល់សេវា',
  MEDICATION_FORM: 'ទម្រង់ថ្នាំ',
  PACK: 'កញ្ចប់',
  IN_STOCK: 'នៅ​ក្នុង​ស្តុក',
  STOCK_LSIT: 'បញ្ជីភាគស្តុក',
  COUNT: 'ចំនួន',
  INSTRUCTION_INFORMATION: 'ព័ត៌មានណែនាំ',
  FREQUENCY: 'ញឹកញាប់',
  ROUTE: 'ផ្លូវ',
  STRENGTH: 'ខ្លាំងបែបណា',
  INSTRUCTIONS_FOR_USE: 'ការណែនាំសម្រាប់ការប្រើប្រាស់',
  COUNT_IN_STOCK: 'ចំនួនដែលនៅក្នុងស្តុក',
  LAST_DELIVERY: 'មកដល់ចុងក្រោយថ្ងៃ',

  // PATIENT VISIT
  VISIT_DATE: 'មកពិនិត្យថ្ងៃ',
  VISIT_CODE: 'លេខកូដពិនិត្យ',
  PATIENT_NAME: 'ឈ្មោះអ្នកជំងឺ',
  VISIT_INFORMAITON: 'ព័ត៌មានចូលព្យាបាល',

  // ISSUED PRESCRIPTION
  ISSUED_PRESCRIPTION: 'ចេញវេជ្ជបញ្ជា',
  QUANTITY: 'បរិមាណ',
  DOSAGE_FORM_MORNING: 'ប្រេីប្រាស់ពេលព្រឹក',
  DOSAGE_FORM_AFTERNOON: 'ប្រេីប្រាស់ពេលរសៀល',
  DOSAGE_FORM_EVENING: 'ប្រេីប្រាស់ពេលល្ងាច',
  DOSAGE_FORM_NIGHT: 'ប្រេីប្រាស់ពេលយប់',
  DETAIL_PRESCRIPTION: 'វេជ្ជបញ្ជាល',

  // FEE SETTING
  MEDICAL_SETTING: 'កំណត់វេជ្ជសាស្រ្ត',
  INVESTIGATION_SETTING: 'កំណត់ការតាមដាន',
  IMAGING_TYPE: 'ប្រភេទថត',
  EXTERNAL_SERVICE: 'សេវាកម្មខាងក្រៅ',
  SERVICE_TYPE: 'ប្រភេទសេវាកម្ម',
  AUTOMATIC_FEE: 'ថ្លៃស្វ័យប្រវត្តិ',
  PROCEDURE_TYPE: 'ប្រភេទនីតិវិធី',
  LABORATORY_TYPE: 'ប្រភេទឡាបូ',
  VACCINATION_TYPE: 'ប្រភេទវ៉ាក់សាំង',
  ALERGY_TYPE: 'ប្រភេទអាឡែស៊ី',
  MEDICAL_SUPPLY_TYPE: 'ប្រភេទនៃការផ្គត់ផ្គង់វេជ្ជសាស្រ្ត',
  MEDICAL_DIAGNOSIS_TYPE: 'ប្រភេទរោគវិនិច្ឆ័យវេជ្ជសាស្ត្រ',
  SHORT_DESCRIPTION: 'ពិពណ៌នាខ្លី',
  LONG_DESCRIPTION: 'ពិពណ៌នាវែង',

  // EQUIPMENT INVENTORY
  EQUIPMENT_TYPE: 'ប្រភេទសំភារះ',
  UNIT_PRICE: 'តម្លៃ​ឯកតា',
  ALERT_COUNT_IN_STOCK: 'ជូនដំណឹងចំនួនដែលនៅសល់',
  RETURN: 'ប្រគល់មកវិញ',
  NOT_RETURN: 'មិនប្រគល់មកវិញ',
  EQUIPMENT: 'សំភារះ',
  REQUEST: 'សំណេីរ',
  NOT_RETURN_ITMES: 'ធាត់ដែលមិនត្រឡប់មកវិញ',
  RETURN_ITEMS: 'ធាតុដែលត្រឡប់មកវិញ',
  ISSUED_BY: 'ចេញដោយ',
  OPEN_REQUEST_EQUIPMENT: 'បេីសំភារះស្នេីរសុំ',
  REQUEST_BY: 'ស្នេីរសុំដោយ',
  LIST_REQUESTED_EQUIPMENTS: 'បញ្ចីសំភារះដែលបានស្នេីរសុំ',

  // FINANCE
  BILL_NUMBER: 'លេខវិក័យប័ត្រ',
  PAYMENT_DATE: 'កាលបរិច្ឆេទទូទាត់',
  TOTAL: 'សរុប',
  START: 'ផ្តើម',
  END: 'បញ្ចប់',
  BILL_STATEMENT: 'របាយការណ៍វិក័យប័ត្រ',
  BILL_LIST: 'បញ្ជីវិក័យប័ត្រ',
  INCOME: 'ចំណូល',
  PRINT_INVOICE: 'បោះពុម្ពវិក្កយបត្រ',
  INVOICE: 'វិក្កយបត្រ',
  TO_PATIENT: 'សំរាប់អ្នកជំងឺ',
  MEDICAL_PACKAGE: 'កញ្ចប់ព្យាបាល',
  DISCOUNT: 'បញ្ចុះតម្លៃ',
  PAYMENT: 'ទូទាត់ប្រាក់',

  PAYMENT_ACCOUNT: 'គណនីទូទាត់',
  AMOUNT: 'ទឹកប្រាក់',
  INCOME_TYPE: 'ប្រភេទចំណូល',
  LIST: 'បញ្ជី',

  EXPENSE_INFO: 'ព័ត៌មានអំពីការចំណាយ',
  EXPENSE: 'ចំណាយ',
  SUPPLIER_NAME: 'អ្នកផ្គត់ផ្គង់',
  TOTAL_AMOUNT: 'ទឹកប្រាក់​សរុប',
  EXPENSE_STATEMENT: 'របាយការណ៍ចំណាយ',
  STATEMENT: 'របាយការណ៍',
  EXPENSE_CODE: 'លេខកូដចំណាយ',
  EXPENSE_TYPE: 'ប្រភេទចំណាយ',
  EXPENSE_DATE_TIME: 'កាលបរិច្ឆេទ',

  PATIENT_RERUND: 'សំណងអ្នកជំងឺ',
  IPD_DATE: 'កាលបរិច្ឆេទ IPD',

  // 31.07.2024
  REGISTERED: 'ចុះឈ្មោះ',
  PERSONAL_CARD: 'ប័ណ្ណផ្ទាល់ខ្លួន',
  PHONE: 'ទូរស័ព្ទ',
  ACTIONS: 'សកម្មភាព',
  SEARCH_PATIENT: 'ស្វែងរកអ្នកជំងឺ',
  ARE_YOU_SURE: 'តើ​អ្នក​ប្រាកដ​ឬ​អត់?',
  CANCEL: 'បោះបង់',
  FOREIGNER: 'ជនបរទេស',
  LASTNAME: 'នាមត្រកូល',
  FIRSTNAME: 'នាមខ្លួន',
  LASTNAME_LATIN: 'នាមត្រកូល (ឡាតាំង)',
  FIRSTNAME_LATIN: 'នាមខ្លួន (ឡាតាំង)',
  DATE_OF_BIRTH: 'ថ្ងៃខែ​ឆ្នាំ​កំណើត',
  PROFESSION: 'វិជ្ជាជីវៈ',
  EMAIL: 'អ៊ីមែល',
  FIRST_PHONE: 'ទូរស័ព្ទជំរេីសទីមួយ',
  SECOND_PHONE: 'ទូរស័ព្ទជំរេីសទីពីរ',
  PERSONAL_CARD_ID: 'ប័ណ្ណផ្ទាល់ខ្លួន',
  NATIONALITY: 'សញ្ជាតិ',
  EMERGENCY_CONTACT: 'លេខ​ទំនាក់ទំនង​បន្ទាន់',
  ADDRESS_INFORMATION: 'ព័ត៌មានអាសយដ្ឋាន',
  CITY_DISTRICT: 'ក្រុង/ស្រុក',
  COMMUNE: 'ឃុំ',
  VILLAGE: 'ភូមិ',
  ADDRESS: 'អាស័យដ្ឋាន',
  HEALTH_INFORMATION: 'ព័ត៌មានសុខភាព',
  FULL_NSSF: 'ប.ស.ស (បេឡាជាតិរបបសន្តិសុខសង្គម)',
  NSSF_REGISTRATION: 'ចុះឈ្មោះបេឡាជាតិរបបសន្តិសុខសង្គម',
  INSURANCE: 'ធានារ៉ាប់រង',
  MALE: 'ប្រុស',
  FEMALE: 'ស្រី',

  // 01.08.2024
  MEDICATION_IS_EMPTY: 'មិនមានទិន្នន័យទេ',
  NAME: 'ឈ្មោះ',
  COUNTRY: 'ប្រទេស',
  DETAIL: 'លម្អិត',
  LIST_OF_DEPARTMENTS: 'បញ្ជីនៃផ្នែក',
  ROOM_NUMBER: 'លេខបន្ទប់',
  COUNT_OF_BEDS: 'ចំនួនគ្រែ',
  ROOM_TYPE: 'ប្រភេទគ្រែ',
  FLOOR: 'ជាន់',
  WARDS_TYPE: 'ប្រភេទវួដ',
  SPECIAL_FEATURES: 'លក្ខណៈ​ពិសេស',
  BED_COUFNT: 'ចំនួនគ្រែ',
  CURRENT_CURRENCY: 'រូបិយប័ណ្ណបច្ចុប្បន្ន',
  BED_NUMBER: 'លេខគ្រែ',
  OCCUPIED: 'បានប្រេីប្រាស់',
  NAME_LATIN: 'ឈ្មោះ (ឡាតាំង)',
  PRICE: 'តម្លៃ',
  LIST_OF_BEDS: 'បញ្ជីនៃគ្រែ',
  LIST_OF_ROOMS: 'បញ្ជីបន្ទប់',
  LIST_OF_WARDS: 'បញ្ជីនៃវួដ',
  SEARCH: 'ស្វែងរក',
  CODE: 'កូដ',
  CREATE_CHILD: 'បង្កើតធាតុខាងក្រោម',
  SELECT_COUNTRY: 'ជ្រើសរើស​ប្រទេស',
  SELECT_MEDICATION_FORM: 'ជ្រើសរើសទម្រង់ថ្នាំ',
  CREATE: 'បង្កើត ',

  // 03.08.2024
  CREATE_INVESTIGATION: 'បង្កើត',
  AUTOMATIC_SERVICE: 'សេវាកម្មដោយស្វ័យប្រវត្តិ',
  OPD: 'OPD',
  IPD: 'IPD',
  YES: 'បាទ|ចាស',
  NO: 'ទេ',
  CONFIRM: 'បញ្ជាក់',
  UPDATE: 'ធ្វើបច្ចុប្បន្នភាព',

  // 04.08.2024
  DESCRIPTION: 'ពិពណ៌នា',
  HEALTH_CARE_DESCRIPTION: 'ពិពណ៌នាអំពីការថែទាំសុខភាព',
  ADD: 'បន្ថែម',
  CATEGORY: 'ប្រភេទ',
  SORT_KEY: 'តម្រៀប',
  TYPE: 'ប្រភេទ',
  PLEASE_SELECT_EQUIPMENT_TO_STOCK: 'សូមជ្រើសរើសឧបករណ៍ដែលត្រូវស្តុកទុក',
  REQUESTED_PERSON: 'បគ្គលដែលបានស្នើសុំ',
  START_DATE: 'ថ្ងៃ​ចាប់ផ្តើម',
  END_DATE: 'កាលបរិច្ឆេទបញ្ចប់',
  APPROVED: 'បានអនុម័ត',
  DO_YOU_STILL_WANT_TO_APPROVE_THIS_REQUEST: 'តើអ្នកនៅតែចង់យល់ព្រមលើសំណើនេះទេ?',
  NO_REQUESTED_EQUIPMENTS: 'មិនមានឧបករណ៍ដែលបានស្នើសុំទេ',
  NOT_APPROVED: 'មិន​ត្រូវ​បាន​អនុម័ត',
  NO_DATA_AVAILABLE: 'មិនមានទិន្នន័យទេ',
  DO_YOU_WANT_TO_DELETED: 'តើអ្នកនៅតែចង់លុបទេ?',
  APPROVE: 'យល់ព្រម',
  TOTAL_DOCTORS: 'វេជ្ជបណ្ឌិតសរុប',
  TOTAL_APPOINTMENTS: 'សរុបចំនួនដែលបានណាត់ជួប',
  TOTAL_REVENUES: 'ប្រាក់ចំណូលសរុប',
  DOCTOR: 'វេជ្ជបណ្ឌិត',
  APPOINTMENT_TYPE: 'ប្រភេទនៃការណាត់ជួប',
  REJECT: 'បដិសេធ',
  ACCEPT: 'ទទួលយក',
  CHECK: 'ពិនិត្យ',

  // 05.08.2024
  EMPLOYEE_INFORMATION: 'ព័ត៌មានបុគ្គលិក',
  ADMINISTRATOR: 'អ្នកគ្រប់គ្រង',
  PAYMENT_METHOD: 'វិធី​សា​ស្រ្ត​ទូទាត់',
  SIGNATURE_OF_AUTHORIZED_PERSON: 'ហត្ថលេខារបស់បុគ្គលដែលមានសិទ្ធិ',
  BILL_STATEMENT_TYPE: 'ប្រភេទនៃសេចក្តីថ្លែងការណ៍របស់វិក័យប័ត្រ',
  START_TIME: 'ពេលវេលាចាប់ផ្តើម',
  END_TIME: 'ពេលវេលាបញ្ចប់',
  PATIENT_PAYMENT_REQUEST_TYPE: 'ប្រភេទសំណើបង់ប្រាក់អ្នកជំងឺ',

  // 07.08.2024
  REASON: 'មូលហេតុ',
  SELECTION: 'ជ្រេីសរេីស',
  FIELD_IS_REQUIRED: 'វាលចាំបាច់ជ្រេីសរេីស',
  INFORMATION: 'ព័ត៌មាន',
  TIME: 'ពេលវេលា',
  CHOOSE: 'ជ្រើសរើស',
  APPLY_TO: 'បញ្ចូលទៅ',
  APPLY_DEPARTMENT_DOCTOR: 'បញ្ញូលទៅផ្នែក | វេជ្ជបណ្ឌិត',
  SELECT_APPLY_TYPE: 'ជ្រើសរើសប្រភេទបញ្ចូន',

  JANUARY: 'មករា',
  FEBRUARY: 'ខែកុម្ភៈ',
  MARCH: 'មីនា',
  APRIL: 'មេសា',
  MAY: 'ឧសភា',
  JUNE: 'មិថុនា',
  JULY: 'កក្កដា',
  AUGUST: 'សីហា',
  SEPTEMBER: 'កញ្ញា',
  OCTOBER: 'តុលា',
  NOVEMBER: 'វិច្ឆិកា',
  DECEMBER: 'ធ្នូ',
  OPERATION_INCOME: 'ប្រាក់ចំណូលប្រតិបត្តិការ',
  USE: 'ប្រើប្រាស់',
  SUPPLIER: 'អ្នកផ្គត់ផ្គង់',
  REFERENCE_NUMBER: 'លេខ​យោង',

  // 08.08.2024
  PATIENT_INFO: 'ព័ត៌មានអ្នកជំងឺ',
  BED_NUMBER_WARD_ROOM: 'គ្រែ | ប្លុក | បន្ទប់',
  ADD_PATIENT_BED: 'ថែម អ្នកជំងឺ | គ្រែ',
  PERSONAL_INFORMATION: 'ព័ត៌មាន​ផ្ទាល់ខ្លួន',
  BED_WARD_ROOM_INFORMATION: 'គ្រែ | ប្លុក | ព័ត៌មានបន្ទប់',
  NURSE_INFORMATION: 'ព័ត៌មានគិលានុបដ្ឋាយិកា',
  ASSIGNED_NURSE_NAME: 'ឈ្មោះគិលានុបដ្ឋាយិកាដែលបានចាត់តាំង',
  ASSIGNED_NURSE_NAME_LATIN: 'ឈ្មោះគិលានុបដ្ឋាយិកាដែលបានចាត់តាំង (ឡាតាំង)',
  PHYSICIAN_INFORMATION: 'ព័ត៌មានអំពីគ្រូពេទ្យ',
  NURSE_AND_PHYSICIAN_INFORMATION: 'គិលានុបដ្ឋាយិកា | គ្រូពេទ្យ',
  ASSIGNED_PHYSICIAN_NAME: 'ឈ្មោះគ្រូពេទ្យដែលបានចាត់តាំង',
  ASSIGNED_PHYSICIAN_NAME_LATIN: 'ឈ្មោះគ្រូពេទ្យ (ឡាតាំង)',
  WARD_NAME: 'ឈ្មោះប្លុក',
  ROOM_NAME: 'ឈ្មោះបន្ទប់',
  NSSF_MEMBER: 'សមាជិក ប.ស.ស',
  DISCHARGE_DATE: 'កាលបរិច្ឆេទបញ្ចេញ',
  BED: 'គ្រែ',
  ROOM: 'បន្ទប់',
  WARD: 'ប្លុក',
  DAILY: 'ប្រចាំថ្ងៃ',
  CONTINUE: 'បន្តជាប្រចាំ',
  DESTINATION: 'គោលដៅ',
  SUMMARY: 'សង្ខេប',
  ADDITIONAL_INFORMATION: 'ព័​ត៍​មាន​បន្ថែម',
  INSTRUCTION_FOR_PATIENT: 'ណែនាំសម្រាប់អ្នកជំងឺ',
  SUBJECTIVE: 'ប្រធានបទ',
  OBJECTIVE: 'កម្មវត្ថុ',
  ASSESSMENT: 'វាយតម្លៃ',
  PLAN: 'ផែនការ',
  APPOINTMENT: 'ណាត់ជួប',
  FROM_MEDICAL_PACKAGE: 'ប្រេីកញ្ចប់ព្យាបាល',
  EXTERNAL: 'សេវាកម្មខាងក្រៅ',
  RESULT: 'លទ្ធផល',
  SEND_REQUESTS_TO_LABORATORY: 'បញ្ជូនសំណើទៅឡាបូ',
  SEND_REQUESTS_TO_IMAGING: 'បញ្ជូនសំណើទៅថតនិងឆ្លុះ',
  PRINT: 'បោះពុម្ព',
  ADD_NEW_PACKAGE: 'បន្ថែមកញ្ចប់ថ្មី',
  MENU_OPTIONS: 'ជម្រើសមុខងារ',
  SUPPLY_TYPE: 'ប្រភេទផ្គត់ផ្គង់',
  ADD_FROM_PATIENT_LIST: 'បន្ថែមពីបញ្ជីអ្នកជំងឺ',
  MEASUREMENT: 'វាស់វែង',
  OPERATION_DATE: 'ថ្ងៃវះកាត់',
  OPERATION_TYPE: 'ប្រភេទវះកាត់',
  REQUEST_DATE: 'ថ្ងៃបានស្នេីរសុំ',
  REFUSE: 'បដិសេធ',
  REASON_TYPE: 'ប្រភេទហេតុផល',
  DATE_OUT: 'កាលបរិច្ឆេទចេញ',
  VOICE_ATTACHMENT: 'សំឡេង | ឯកសារភ្ជាប់',
  ATTACHMENT: 'ឯកសារភ្ជាប់',
  VOICE: 'សំឡេង',
  CURRENT: 'បច្ចុប្បន្ន',
  HISTORY: 'ប្រវត្តិ',
  HISTORY_DATE: 'កាលបរិច្ឆេទប្រវត្តិ',
  WARD_TYPE: 'បញ្ចូនទៅផ្នែក',
  OBSERVATION_REVIEW: 'ត្រួតពិនិត្យនិងតាមដានឡេីងវិញ',
  SERVICE: 'សេវាកម្ម',
  INCREASE_PRICE: 'បង្កើនតម្លៃ',
  DECREASE_PRICE: 'បញ្ចុះតម្លៃ',
  CALCULATED_PRICE: 'តម្លៃដែលបានគណនា',
  ITEM: 'ធាតុ',
  OVER_PACKAGE: 'លើសដែលមានក្នុងកញ្ចប់',
  LIST_OF_PATIENTS: 'បញ្ជីអ្នកជំងឺ',
  APPLY_PATIENT_BED: 'អនុវត្តទៅ អ្នកជំងឺ | គ្រែ',
  LIST_OF_PATIENT_REFUNDS: 'បញ្ជីនៃការសងប្រាក់វិញ',
  REFUND_DATE: 'កាលបរិច្ឆេទសងប្រាក់វិញ',
  REASON_OF_REFUND: 'ហេតុផលនៃការសងប្រាក់វិញ',
  VISIT_CODE_DATE: 'លេខកូចូលពេទ្យ | កាលបរិច្ឆេទ',
  ADMISSION_CODE_DATE: 'លេខកូដចូលសំរាកពេទ្យ | កាលបរិច្ឆេទ',
  OPERATING_EXPENSE: 'ប្រតិបត្តិការចំណាយ',
  OPERATING_INCOME: 'ប្រតិបត្តិការចំណូល',
  TOTAL_PRICE: 'តម្លៃសរុប',
  SOURCE: 'ប្រភព',
  DEPOSIT_DATE: 'កាលបរិច្ឆេទដាក់ប្រាក់',
  NUMBER_OF_DEPOSIT: 'ចំនួននៃការដាក់ប្រាក់',
  DEPOSIT_SOURCE: 'ប្រភពដាក់ប្រាក់',
  FINANCIAL_DEPOSIT: 'ប្រាក់បញ្ញើហិរញ្ញវត្ថុ',
  BREAKDOWN_OF_DEPOSIT_SOURCE: 'បំបែកប្រភពប្រាក់បញ្ញើ',
  SUMMARY_OF_DEPOSITS: 'សេចក្តីសង្ខេបនៃប្រាក់បញ្ញើ',
  MONTH: 'ខែ',
  YEAR: 'ឆ្នាំ',
  // 10.08.2024
  ASSET: 'ទ្រព្យសម្បត្តិ',
  ACCOUNT: 'គណនី',
  LIABILITY: 'ធារារ៉ាប់រង',
  EQUITY: 'ទ្រព្យសម្បត្តិផ្ទាល់ខ្លួន',
  FINANCIAL_BALANCE_SHEET_DATE: 'កាលបរិច្ឆេទតារាងតុល្យការហិរញ្ញវត្ថុ',
  CASH_FLOW_ANALYSIS: 'វិភាគលំហូរសាច់ប្រាក់',
  NUMBER_OF_TRANSACTIONS: 'ចំនួនប្រតិបត្តិការ',
  INCOME_GENERETED: 'ប្រាក់ចំណូលបានបង្កើត',
  PROCEDURE: 'នីតិវិធីវេជ្ជសាស្រ្ត',
  PROVIDING_MEDICATION: 'ផ្តល់ថ្នាំ',
  DOSAGE: 'កិតើ',
  PROVIDED_TIME: 'ពេលវាលាផ្តល់ថ្នាំ',
  YES_PROVIDE: 'បាទ | ចាស​ ផ្តាល់អោយ',
  INSTRUCTION: 'បង្រៀន',
  NR: 'លេខរៀង',
  TEMPLATE: 'គំរូ',
  LETTER: 'សំបុត្រ',
  SHOW: 'បង្ហាញ',
  LOT_NUMBER: 'លេខឡូតិ៍',
  LOCATION: 'ទីតាំង',
  HOME_PRESCRIPTION: 'វេជ្ជបញ្ជានៅផ្ទះ',
  IMAGING: 'ថត | ឆ្លុះ',
  MEDICAL_CERTIFICATE: 'លិខិតរវេជ្ជសាស្រ្ត',
  USER_INFORMATION: 'ព័ត៌មានអ្នកប្រើប្រាស់',
  USER_NAME: 'ឈ្មោះអ្នកប្រើប្រាស់',
  DASHBOARD: 'ផ្ទាំងគ្រប់គ្រង',
  LOGOUT: 'ចាកចេញពីប្រពន្ធ័',
  YOUR_DATA_HAS_BEEN_SAVED_SUCCESSFULLY: 'ទិន្នន័យរបស់អ្នកត្រូវបានរក្សាទុកដោយជោគជ័យ',
  FILE_NAME: 'ឈ្មោះឯកសារ',
  FILE_TYPE: 'ប្រភេទឯកសារ',
  FILE_SIZE: 'ទំហំឯកសារ',
  LIST_OF_INSURANCES: 'បញ្ជីធានារ៉ាប់រង',
  LIST_OF_SUPPLIERS: 'បញ្ជីអ្នកផ្គត់ផ្គង់',
  CONTACT_PERSON: 'អ្នកទំនាក់ទំនង',
  CONTACT_PHONE: 'ទូរស័ព្ទទំនាក់ទំនង',
  CONTACT_EMAIL: 'អ៊ីមែលទំនាក់ទំនង',
  VITAL_SIGN_HISTORY: 'ប្រវត្តិនៃសញ្ញាសំខាន់',
  PRESCRIPTION_HISTORY: 'ប្រវត្តិវេជ្ជបញ្ជា',
  APPOINTMENT_HISTORY: 'ប្រវត្តិនៃការណាត់ជួប',
  NOTE_HISTORY: 'ប្រវត្តិកំណត់ត្រា',
  MEDICAL_CERTIFICATE_HISTORY: 'ប្រវត្តិលិខិត',
  ACTIVE: 'កំពុងមានសកម្មភាព',
  INACTIVE: 'លែងមានសកម្មភាព',
  IPD_INFO: 'ព័ត៌មាន IPD',
  MEDICATION_INFORMATION: 'ព័ត៌មានអំពីថ្នាំ',
  SEARCH_MEDICATION_FORM: 'ស្វែងរកប្រភេទថ្នាំ',
  EXPIRED_DATE: 'កាលបរិច្ឆេទផុតកំណត់',
  PHYSCAL_EXAMINATION: 'ពិនិត្យរាងកាយ',
  WEEK: 'សប្តាហ៍',
  LIST_OF_PAID_PAYMENTS: 'បញ្ចីទឹកប្រាក់ដែលបានបង់',
  REGISTERED_NUMBER: 'លេខដែលបានចុះឈ្មោះ',
  INVOICE_INFORMATION: 'ព័ត៌មានវិក្កយបត្រ',
  INVOICE_NUMBER: 'លេខវិក្កយបត្រ',
  INVOICE_DATE: 'កាលបរិច្ឆេទវិក្កយបត្រ',
  TOTAL_PAYMENT_AMOUNT: 'ចំនួនទឹកប្រាក់ទូទាត់សរុប',
  TOTAL_PAID_AMOUNT: 'ចំនួនទឹកប្រាក់ដែលបានបង់សារុប',
  TOTAL_AMOUNT_REMAINING: 'ចំនួនទឹកប្រាក់សរុបដែលនៅសល់សារុប',
  OPD_INFO: 'ព័ត៌មាន OPD',
  END_PRICE: 'តំលៃចុងក្រោយ',
  TOTAL_PAYMENT: 'ទឹកប្រាក់សារុបដែលត្រូវបង់',
  PAYMENT_BY: 'បង់ប្រាក់ដោយ',
  REFRESH: 'កំណត់បច្ចុប្បន្នឡេីងវិញ',
  TODAY: 'ថ្ងៃនេះ',
  NUMBER_OF_VISITS: 'ចំនួនអ្នកជំងឺដែលបានមកព្យាបាល',
  NUMBER_OF_ADMISSIONS: 'ចំនួនអ្នកជំងឺដែលបានមកសំរាកព្យាបាល',
  ACCOUNTS_RECEIVALBE_AGING_BREAKDOWN: 'គណនីទទួលប្រាក់បំបែកតាមអាយុកកាល',
  AGE_CATEGORY: 'ប្រភេទអាយុកកាល',
  AMOUNT_OUTSTANDING: 'ចំនួនទឹកប្រាក់ដែលនៅសល់',
  ACCOUNTS_PAYABLE_AGING_BREAKDOWN: 'គណនីត្រូវបង់ប្រាក់បំបែកតាមអាយុកកាល',
  TOTAL_OUTSTANDING_BALANCE: 'សមតុល្យសរុប',
  OUTSTANDING_BALANCE: 'សមតុល្យដែលមិនបានទូួទាត់',
  DISCOUNT_OUTSTANDING: 'ការបញ្ចុះតម្លៃដែលមិនបានទូទាត់',
  DISCOUNT_BREAKDOWN_BY_SERVICE: 'ការបែងចែកការបញ្ចុះតម្លៃតាមសេវាកម្ម',
  SERVICE_INFO: 'ព័ត៌មានសេវាកម្ម',
  VALUE: 'តម្លៃ',
  AVERAGE: 'មធ្យម',
  DEPOSIT_BREAKDOWN_BY_SERVICE: 'ការបែងចែកប្រាក់បញ្ញើតាមសេវាកម្ម',
  AVERAGE_DEPOSIT: 'ប្រាក់បញ្ញើជាមធ្យម',
  BALANCE_BREAKDOWN_BY_SUPPLIER: 'ការវិភាគសមតុល្យដោយអ្នកផ្គត់ផ្គង់',
  NUMBER_OF_INVOICES: 'ចំនួនវិក្កយបត្រ',
  PAYMENT_DUE_DATE: 'កាលបរិច្ឆេទទូទាត់',
  MONTHLY_BALANCE_DISTRIBUTION: 'ការចែកចាយសមតុល្យប្រចាំខែ',
  DEPOSIT_BREAKDOWN_BY_PAYMENT_METHOD: 'ការបែងចែកប្រាក់បញ្ញើតាមវិធីបង់ប្រាក់',
  NUMBER: 'លេខ',
  ITEM_OF_EXPENSE: 'ធាតុនៃចំណាយ',
  DETAIL_INFORMATION: 'ព័ត៌មានលំអិត',
  EXPENSE_DATE: 'កាលបរិច្ឆេទចំណាយ',
  REFERENCE: 'ឯកសារយោង',
  ASSIGNED_INFORMATION: 'ព័ត៌មានដែលទាក់ទង',
  GENERAL_INFORMATION: 'ព័ត៌មានទូទៅ',
  INCOME_DATE: 'កាលបរិច្ឆេទចំណូល',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_REMOVED: 'ទិន្នន័យរបស់អ្នកត្រូវបានដកចេញដោយជោគជ័យ',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_UPDATED: 'ទិន្នន័យរបស់អ្នកត្រូវបានធ្វើបច្ចុប្បន្នភាពដោយជោគជ័យ',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_CREATED: 'ទិន្នន័យរបស់អ្នកត្រូវបានបង្កើតដោយជោគជ័យ',
  REASON_FOR_REFUND: 'ហេតុផលសម្រាប់ការសងប្រាក់វិញ',
  YOUR_DATA_HAS_BEEN_SUCCESSFULLY_SAVED: 'ទិន្នន័យរបស់អ្នកត្រូវបានរក្សាទុកដោយជោគជ័យ',
  ARE_YOU_SURE_WANT_TO_DELETE: 'តើអ្នកពិតជាចង់លុបមែនទេ?',
  BILL_DATE: 'កាលបរិច្ឆេទវិក័យប័ត្រ',
  UPLOAD_ATTACHMENT: 'ផ្ទុកឯកសារភ្ជាប់',
  MEDICATION_FROM_PROVIDER: 'ថ្នាំពីអ្នកផ្តល់សេវា',
  PATIENT_APPOINTMENT: 'ការណាត់ជួបអ្នកជំងឺ',
  RESPIRATORY_SYTSTEM: 'ប្រព័ន្ធផ្លូវដង្ហើម',
  PDF_VIEWER: 'មេីលជា PDF',
  JOB_STATUS: 'ស្ថានភាពការងារ',
  JOB_TYPE: 'ប្រភេទការងារ',
  FACTORY_NUMBER: 'លេខរោងចក្រ',
  PATENT_NUMBER: 'លេខប៉ាតង់',
  START_WORK_DATE: 'កាលបរិច្ឆេទចាប់ផ្តើមការងារ',
  WORKING_STATUS: 'ស្ថានភាពការងារ',
  APPLY: 'អនុវត្ត',
  APPLIED: 'បានអនុវត្ត',
  CHOOSED: 'បានជ្រើសរើស',
  USERNAME: 'ឈ្មោះអ្នកប្រើប្រាស់',
  NURSE: 'គិលានុបដ្ឋាយិកា',
  ASSIGN_TO: 'ចាត់តាំងទៅ',
  ADMIN_STAFF: 'បុគ្គលិករដ្ឋបាល',
  DELETE_USER: 'លុបអ្នកប្រើប្រាស់',
  ACCESS_MODULE_LIST: 'បញ្ជីម៉ូឌុល',
  EXECUTE: 'ប្រតិបត្តិ',
  MENU_BY_ROLE: 'ម៉ឺនុយតាមតួនាទី',
  VIEW: 'មើល',
  ASSIGN: 'ចាត់តាំង',
  ASSIGN_MODULE: 'កំណត់ម៉ូឌុល',
  LABORATORY_IMAGING: 'ឡាបូ | ថតឬឆ្លុះ',
  BY: 'ដោយ',
  CHECK_EXCHANGE_RATE: 'ពិនិត្យអត្រាប្តូរប្រាក់',
  WORKING_DAYS: 'ថ្ងៃធ្វើការ',
  TIME_TABLES: 'តារាងពេលវេលា',
  CURRENCY_TYPE: 'ប្រភេទរូបិយប័ណ្ណ',
  TELEGRAM_NAME: 'ឈ្មោះ Telegram',
  SYSTEM_FEATURES: 'មុខងារប្រេីប្រព័ន្ធ',
  THE_CLINIC_HOSPITAL_TYPE: 'ប្រភេទ គ្លីនិក | មន្ទីរពេទ្យ',
  THE_CLINIC_HOSPITAL_INFO: 'ព័ត៌មាន គ្លីនិក | មន្ទីរពេទ្យ',
  MONDAY: 'ថ្ងៃច័ន្ទ',
  TUESDAY: 'ថ្ងៃអង្គារ',
  WEDNESDAY: 'ថ្ងៃពុធ',
  THURSDAY: 'ថ្ងៃព្រហស្បតិ៍',
  FRIDAY: 'ថ្ងៃសុក្រ',
  SUTURDAY: 'ថ្ងៃសៅរ៍',
  SUNDAY: 'ថ្ងៃអាទិត្យ',
  EXCHANGE_RATE: 'អត្រាប្តូរប្រាក់',
  MORNING: 'ព្រឹក',
  AFTERNOON: 'រសៀល',
  EVENING: 'ល្ងាច',
  REFERENCE_RANGE_PEDIATRIC: 'ជួរយោង (កុមារ)',
  REFERENCE_RANGE_ADULT_FEMALE: 'ជួរយោង (ស្ត្រីពេញវ័យ)',
  REFERENCE_RANGE_ADULT_MALE: 'ជួរយោង (បុរសពេញវ័យ)',
  UNIT: 'ឯកតា',
  SEARCH_ENTER: 'ស្វែងរក (ពាក្យស្វែងរក)',
  ID: 'លេខកូដ',
  LABORATORY: 'មន្ទីរពិសោធន៍',
  LABORATORY_ITEM: 'ធាតុមន្ទីរពិសោធន៍',
  LABORATORY_TEST_TEMPLATE: 'គំរូតេស្តឡាបូ',
  REQUESTED_DATE: 'កាលបរិច្ឆេទដែលបានស្នើសុំ',
  RESULT_DATA_ENTRY: 'បញ្ចូលទិន្នន័យលទ្ធផល',
  RESULT_DATA_UPLOAD: 'Upload ទិន្នន័យលទ្ធផល',
  PENDING: 'កំពុងរង់ចាំ',
  DONE: 'រួចរាល់',
  PLEASE_SELECT_DATE: 'សូមជ្រើសរើសកាលបរិច្ឆេទ',
  BENEFIT_VALUE: 'តម្លៃអត្ថប្រយោជន៍',
  LABORATORY_TEST_RESULT: 'លទ្ធផលតេស្តរបស់ឡាបូ',
  LABORATORY_TEST_UNIT: 'ខ្នាតតេស្តរបស់ឡាបូ',
  CLINIC_LOCATION: 'ទីតាំង',
  PAYMENT_INFORMATION: 'ព័ត៌មានអំពីការទូទាត់ប្រាក់',
  ADD_PAYMENT: 'បន្ថែមការបង់ប្រាក់',
  PAID_AMOUNT: 'ចំនួនទឹកប្រាក់ដែលបានបង់',
  SELECTED: 'បានជ្រេីសរេីស',
  SELECT: 'ជ្រេីសរេីស',
  PLEASE_SECTIONS: 'សូមធ្វេីការជ្រេីសរេីស',
  REASON_FOR_VISIT: 'មូលហេតុមកពិនិត្យ',
  DOCTOR_DETAILS: 'ព័ត៌មានលម្អិតរបស់វេជ្ជបណ្ឌិត',
  DOCTOR_SESSION: 'វគ្គជួបគ្រូពេទ្យ',
  DOCTOR_BENEFITS: 'អត្ថប្រយោជន៍របស់វេជ្ជបណ្ឌិត',
  NURSE_DETAILS: 'ព័ត៌មានលំអិតរបស់គិលានុបដ្ឋាយិកា',
  STAFF_DETAILS: 'ព័ត៌មានលម្អិតអំពីបុគ្គលិក',
  PAYMENT_BILL_STATUS: 'ស្ថានភាពវិក័យប័ត្រទូទាត់',
  QUALIFICATIONS: 'គុណវុឌ្ឍិ',
  SPECIALTY: 'ឯកទេស',
  CLINICAL_EXPERTISE: 'ជំនាញព្យាបាល',
  PROFESSIONAL_MEMBERSHIPS_AND_AFFILIATIONS: 'សមាជិកភាពវិជ្ជាជីវៈ និងសម្ព័ន្ធភាព',
  PATIENT_REVIEWS_AND_TESTIMONIALS: 'ពិនិត្យអ្នកជំងឺ និងសក្ខីកម្ម',
  BENEFIT_PERCENTAGE: 'ភាគរយនៃអត្ថប្រយោជន៍',
  QUALIFICATIONS_DETAILS: 'ព័ត៌មានលម្អិតអំពីគុណវុឌ្ឍិ',
  SPECIALTY_DETAILS: 'ព័ត៌មានលម្អិតពិសេស',
  CLINICAL_EXPERTISE_DETAILS: 'ព័ត៌មានលម្អិតអំពីជំនាញគ្លីនិក',
  PROFESSIONAL_MEMBERSHIP_AND_AFFILIATION_DETAILS:
    'សមាជិកភាពវិជ្ជាជីវៈ និងព័ត៌មានលម្អិតអំពីសម្ព័ន្ធភាព',
  PATIENT_REVIEWS_AND_TESTIMONIALS_DETAILS: 'ពិនិត្យអ្នកជំងឺ និងព័ត៌មានលម្អិតអំពីសក្ខីកម្ម',
  LIST_OF_PATIENT_INSURANCE_CLAIMS: 'បញ្ជីតារាងទាមទារប្រាក់ពីធានារ៉ាប់រង',
  AMOUNT_CLAIM: 'ចំនួនទឹកប្រាក់ទាមទារ',
  DESCRIPTION_OF_CLAIM: 'ពិពណ៌នាអំពីការទាមទារ',
  DATE_OF_CLAIM: 'កាលបរិច្ឆេទនៃការទាមទារ',
  BILL_INFORMATION: 'ព័ត៌មានវិក័យប័ត្រ',
  INSURANCE_CLAIM: 'ទាមទារសំណងពីធានារ៉ាប់រង',
  TOTAL_AMOUNT_OWED: 'ចំនួនសរុបដែលជំពាក់',
  TOTAL_DISCOUNTS_GIVEN: 'បញ្ចុះតម្លៃសរុបដែលបានផ្តល់ឱ្យ',
  TOTAL_NUMBER_OF_DISCOUNTS_APPLIED: 'ចំនួនសរុបនៃការបញ្ចុះតម្លៃត្រូវបានអនុវត្ត',
  AVERAGE_DISCOUNT_PER_SERVICE: 'បញ្ចុះតម្លៃជាមធ្យមក្នុងមួយសេវាកម្ម',
  DOSAGE_FORM: 'ទម្រង់កិតើ',
  STOCK_AT_START: 'ចាប់ផ្តេីមក្នុងឃ្លាំង',
  DISPENSED: 'បានចែកចាយ',
  CURRENT_STOCK: 'បច្ចុប្បន្នភាពក្នុងឃ្លាំង',
  RESTOCK_NEEDED: 'ត្រូវការស្តុកឡើងវិញ',
  HIGH_DEMAND_MEDICATIONS: 'ថ្នាំដែលមានតម្រូវការខ្ពស់',
  LOW_DEMAND_MEDICATIONS: 'ថ្នាំដែលមានតម្រូវការទាប',
  CRITICAL_SHORTAGES: 'ថ្នាំដែលកង្វះខាតខ្លាំងបំផុត',
  STOCK_DELAYS: 'ថ្នាំដែលមានក្នុងឃ្លាំង',
  PRESCRIPTION_ADJUSTMENTS: 'ថ្នាំដែលកែតម្រូវតាមវេជ្ជបញ្ជា',
  MEDICATION_INVENTORY_AND_USAGE_SUMMARY: 'បញ្ជីសារពើភណ្ឌឱសថ និងសេចក្តីសង្ខេបការប្រើប្រាស់',
  TOTAL_INCOME: 'ប្រាក់ចំណូលសរុប',
  TOTAL_EXPENSE: 'ចំណាយសរុប',
  NET_REVENUE: 'ប្រាក់ចំណូលសុទ្ធ',
  OPENING_CASH_BALANCE: 'បើកសមតុល្យសាច់ប្រាក់',
  CASH_INFLOWS: 'លំហូរសាច់ប្រាក់ចូល',
  CASH_OUTFLOWS: 'លំហូរសាច់ប្រាក់ចេញ',
  CLOSING_CASH_BALANCE: 'បិទសមតុល្យសាច់ប្រាក់',
  TOTAL_ACCOUNT_RECEIVABLES: 'គណនីទទួលសរុប',
  CURRENT_RECEIVABLES: 'បំណុលបច្ចុប្បន្ន',
  OVERDUE_RECEIVABLES: 'បំណុលហួសកាលកំណត់',
  DAYS_0_30: '0-30 ថ្ងៃ',
  DAYS_31_60: '31-60 ថ្ងៃ',
  DAYS_61_90: '61-90 ថ្ងៃ',
  DAYS_91_120: '91-120 ថ្ងៃ',
  TOTAL_AMOUNT_OWED_TO_SUPPLIERS: 'ចំនួនប្រាក់សរុបដែលជំពាក់អ្នកផ្គត់ផ្គង់',
  TOTAL_NUMBER_OF_SUPPLIERS: 'ចំនួនសរុបដែលជំពាក់អ្នកផ្គត់ផ្គង់',
  AVERAGE_AMOUNT_OWED_PER_SUPPLIER: 'ចំនួនមធ្យមដែលជំពាក់ក្នុងមួយអ្នកផ្គត់ផ្គង់',
  TOTAL_DEPOSITS_COLLECTED: 'ប្រាក់បញ្ញើសរុបត្រូវបានប្រមូល',
  TOTAL_NUMBER_OF_DEPOSITS: 'ចំនួនសរុបនៃការដាក់ប្រាក់',
  AVERAGE_DEPOSIT_AMOUNT: 'ចំនួនប្រាក់បញ្ញើជាមធ្យម',

  EQUIPMENT_INVENTORY_OVERVIEW: 'របាយការណ៍សង្ខេប',
  DETAILED_EQUIPMENT_INVENTORY_REPORT: 'ឧបករណ៍បរិក្ខារលម្អិត',
  INVENTORY_OF_MEDICAL_SUPPLIES: 'របាយការណ៍ពីការផ្គត់ផ្គង់ផ្នែកវេជ្ជសាស្រ្ត',
  EQUIPMENT_MAINTENANCE_REPORT: 'ឧបករណ៍បរិក្ខារការិយាល័យ',
  MAINTENANCE_REPORT: 'របាយការណ៍ថែទាំបរិក្ខារ',
  SUMMARY_OF_REPAIRS: 'របាយការណ៍សង្ខេបនៃការជួសជុល',
  SUMMARY_OF_LOW_STOCK_INVENTORY: 'របាយការណ៍បរិក្ខារដែលមានតិចក្នុងស្តុក',

  TOTAL_ITEMS: 'ធាតុសរុប',
  IN_USE: 'កំពុងប្រើ',
  IN_STORAGE: 'នៅក្នុងឃ្លាំង',
  DAMAGED_UNDER_REPAIR: 'ជួសជុលប្រើប្រាស់ដែលខូច',
  FUNCTIONAL_ITEMS: 'ធាតុដែលដំណេីរការ',
  NON_FUNCTIONAL_ITEMS: 'ធាតុដែលមិនដំណេីរការ',
  EQUIPMENT_NAME: 'ឈ្មោះឧបករណ៍',
  PURCHASE_DATE: 'កាលបរិច្ឆេទទិញ',
  CONDITION: 'លក្ខខណ្ឌ',
  LAST_MAINTENANCE_DATE: 'កាលបរិច្ឆេទថែទាំចុងក្រោយ',
  NEXT_MAINTENANCE_DATE: 'កាលបរិច្ឆេទថែទាំបន្ទាប់',
  REMARKS: 'ការកត់សម្គាល់',
  ITEM_NAME: 'ឈ្មោះធាតុ',
  STOCK_QUANTITY: 'បរិមាណស្តុក',
  REORDER_LEVEL: 'កំរិតបញ្ជាទិញ',
  EXPIRY_DATE: 'កាលបរិច្ឆេទផុតកំណត់',
  MAINTENANCE_TYPE: 'ប្រភេទថែទាំ',
  MAINTENANCE_DATE: 'កាលបរិច្ឆេទថែទាំ',
  SERVICED_BY: 'សេវាកម្មដោយ',
  ACTION_REQUIRED: 'សកម្មភាពចាំបាច់',
  REPLACEMENT_DATE: 'កាលបរិច្ឆេទជំនួស',

  MEDICATION_INVENTORY_SUMMARY: 'សង្ខេបសារពើភ័ណ្ឌឱសថ',
  DETAILED_MEDICATION_STOCK_REPORT: 'របាយការណ៍ឱសថដែលមានក្នុងស្តុក',
  EXPIRED_MEDICATION_REPORT: 'របាយការណ៍ឱសថដែលផុតកំណត់',
  LOW_STOCK_MEDICATIONS: 'ឱសថដែលមានតិចក្នុងស្តុក',
  MEDICATION_USAGE_REPORT: 'របាយការណ៍ការប្រើប្រាស់ឱសថ',
  MEDICATION_REORDER_REPORT: 'របាយ​ការណ៍​បញ្ជា​​ឱសថដែលទិញឡើងវិញ',
  MEDICATION_DISPOSAL_RECORD: 'កំណត់ត្រាការចោលឱសថ',

  MEDICATION_CATEGORY: 'ប្រភេទឱសថ',
  TOTAL_MEDICATIONS: 'ឱសថសរុប',
  LOW_STOCK: 'តិចបំផុតក្នុងស្តុក',
  EXPIRED: 'ផុតកំណត់',
  REORDERED: 'បានបញ្ចារទិញឡេីងវិញ',
  MEDICATION_NAME: 'ឈ្មោះឱសថ',
  ACTION_TAKEN: 'សកម្មភាពដែលបានធ្វើឡើង',
  REORDER_STATUS: 'ស្ថានភាពបញ្ចារទិញឡេីងវិញ',
  USAGE_PERIOD: 'រយៈពេលប្រើប្រាស់',
  QUANTITY_USED: 'បរិមាណប្រើប្រាស់',
  REMAINING_STOCK: 'ឱសថដែលនៅសល់',
  DATE_REORDERED: 'កាលបរិច្ឆេទបញ្ចារទិញឡេីងវិញ',
  QUANTITY_REORDERED: 'បរិមាណបញ្ចារទិញឡេីងវិញ',
  EXPECTED_DELIVERY: 'ការដឹកជញ្ជូនដែលរំពឹងទុក',
  QUANTITY_DISPOSED: 'បរិមាណបោះចោល',
  DATE_DISPOSED: 'កាលបរិច្ឆេទបោះចោល',
  LAST_MAINENANCE: 'ការថែរក្សាចុងក្រោយ',
  NEXT_MAINENANCE: 'ការថែទាំបន្ទាប់',
  MAINENANCE_STATUS: 'ស្ថានភាពថែទាំ',

  EQUIPMENT_CATEGORY: 'ប្រភេទឧបករណ៍',
  TOTAL_EQUIPMENTS: 'ឧបករណ៍សរុប',
  UNDER_REPAIR: 'ក្រោម​ការ​ជួសជុល',
  FUNCTIONAL: 'មានមុខងារ',
  NON_FUNCTIONAL: 'លែងមានមុខងារ',
  EXPECTED_DELIVERY_DATE: 'កាលបរិច្ឆេទនៃការចែកចាយដែលរំពឹងទុក',
  DISPOSAL_DATE: 'កាលបរិច្ឆេទបោះចោល',
  METHOD_OF_DISPOSAL: 'វិធីសាស្រ្តនៃការចោល',
  ADVANCED_SEARCH: 'ស្វែងរកកម្រិតខ្ពស់',
  TIME_SLOT: 'ចន្លោះពេល',
  BACK_TO_QUICK_SEARCH: 'ត្រឡប់ទៅ ការស្វែងរករហ័ស',
  BENEFIT_PERCENTAGE_VALUE: 'តម្លៃអត្ថប្រយោជន៍',
  BENEFIT_TYPE: 'ប្រភេទអត្ថប្រយោជន៍',
  EXPORT_TO_EXCEL:'នាំចេញទៅ Excel',
  IMPORT_FROM_EXCEL:'នាំចូលពី Excel',
  SEARCH_LABORATORY_TEST:'ស្វែងរកការធ្វើតេស្តមន្ទីរពិសោធន៍',
  SEARCH_IMAGING:'ស្វែងរកការវិធីថតឬឆ្លុះ',
  SEARCH_LABORATORY_TEST_IMAGING:'ស្វែងរកការធ្វើតេស្តមន្ទីរពិសោធន ឬ​ ថតនិងឆ្លុះ',
  COMPONENTS:'សមាសធាតុ',

  EXECUTIVE_SUMMARY:'សង្ខេបនៃប្រតិបត្តិ',
  PATIENT_DEMOGRAPHICS_AGE:'អ្នកជំងឺ (អាយុ)',
  PATIENT_DEMOGRAPHICS_GENDER:'អ្នកជំងឺ (ភេទ)',
  PATIENT_DEMOGRAPHICS_ETHNICITY:'អ្នកជំងឺ (ជាតិសាស្រ្ត)',
  PATIENT_VISITS_AND_APPOINTMENTS:'អ្នកជំងឺមកព្យាបាលនិងណាត់ជួប',
  SHORT_NAME:'ឈ្មោះខ្លី',
  ADD_FROM_PROVIDER:'ថែមថ្មីពីអ្នកផ្គត់ផ្គង់',

  SURGICAL_PATIENT:'អ្នកជំងឺវះកាត់',
  SURGICAL_TEAM:'ក្រុមវះកាត់',
  SURGICAL_TYPE:'ប្រភេទវះកាត់',
  SURGICAL_DATE:'កាលបរិច្ឆេទវះកាត់',
  DURATION:'រយៈពេល',
  SURGICAL_INFORMATION:'ព័ត៌មានវះកាត់',
  SURGICAL_TIME:'ពេលវេលាវះកាត់',
  TEAM_NAME:'ឈ្មោះក្រុម',
  ASSISTANT:'ជំនួយការ',
  ANESTHETIST:'អ្នកចាក់ថ្នាំស្ពឹក',
  HELPER:'ជំនួយ',
  INSURANCE_DETAILS:'ព័ត៌មានលម្អិតអំពីធានារ៉ាប់រង',
  SURGICAL_ROOM_INFORMATION:'ព័ត៌មានបន្ទប់វះកាត់',
  EQUIPMENT_INFORMATION:'ព័ត៌មានអំពីឧបករណ៍',
  DOCTORS:'វេជ្ជបណ្ឌិត',
  ASSISTANT_DOCTORS:'ជំនួយការវេជ្ជបណ្ឌិត',
  ANESTHETISTS:'អ្នកចាក់ថ្នាំស្ពឹក',
  HELPERS:'អ្នកជំនួយ',
};

export default kh;
