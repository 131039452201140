import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import Iconify from '../../iconify';
import { LoadingButton } from '@mui/lab';

MultiFilePreviewTable.propTypes = {
  files: PropTypes.array, // Can now handle both `File` objects and `image_data` objects
  onRemove: PropTypes.func,
};

export default function MultiFilePreviewTable({ files, onRemove }) {
  const handlePreview = (file) => {
    // If the file is an object from `image_data`, use the `file_display` or `file_thumbnail`
    const previewUrl =
      file instanceof File ? URL.createObjectURL(file) : file.file_display || file.file_thumbnail;
    window.open(previewUrl, '_blank');
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell colSpan={2} align="center">
              Action
            </TableCell>
            {/* <TableCell>Delete</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file, index) => {
            // Handle both `File` objects and `image_data` objects
            const fileName =
              file instanceof File ? file.name : file.file_original_name || 'Unknown File';

            return (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="body2">{fileName}</Typography>
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon="mdi:eye" />}
                    onClick={() => handlePreview(file)}
                  >
                    Show
                  </Button>
                </TableCell>
                <TableCell>
                  <LoadingButton variant="contained" color="error" onClick={() => onRemove(file)}>
                    <Iconify icon="mdi:delete" />
                  </LoadingButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
