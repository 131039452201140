import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { Box, Stack, Button, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
// assets
import { UploadIllustration } from '../../assets/illustrations';
//
import RejectionFiles from './errors/RejectionFiles';

import SingleFilePreview from './preview/SingleFilePreview';
import MultiFilePreviewTable from './preview/MultiFilePreviewTable';

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  '&:hover': {
    opacity: 0.72,
  },
}));

UploadTable.propTypes = {
  sx: PropTypes.object,
  error: PropTypes.bool,
  files: PropTypes.array,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onDelete: PropTypes.func,
  onRemove: PropTypes.func,
  onUpload: PropTypes.func,
  thumbnail: PropTypes.bool,
  helperText: PropTypes.node,
  onRemoveAll: PropTypes.func,
  setFiles: PropTypes.func, // Use setFiles as a function
  initialFiles: PropTypes.array,
  autoUpload: PropTypes.bool, // New prop to control immediate upload
};

export default function UploadTable({
  disabled,
  multiple = true, // Default to true
  error,
  helperText,
  file,
  onDelete,
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  setFiles,
  initialFiles,
  autoUpload = false, // Default to false
  sx,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled,
    ...other,
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((fileImg) =>
        Object.assign(fileImg, {
          preview: URL.createObjectURL(fileImg), // Generate preview URL
        })
      );

      // Update the files state
      setFiles((prevFiles) => (multiple ? [...newFiles, ...prevFiles] : newFiles));

      // Trigger upload immediately if autoUpload is true
      if (autoUpload && onUpload) {
        onUpload(newFiles); // Call upload function from the parent
      }
    },
  });

  useEffect(() => {
    // Set the initial file state from initialFiles
    if (initialFiles) {
      setFiles(initialFiles);
    }
  }, [initialFiles, setFiles]);

  const hasFile = !!file && !multiple;
  const hasFiles = files && multiple && files.length > 0;
  const isError = isDragReject || !!error;

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasFile && {
            padding: '12% 0',
          }),
        }}
      >
        <input {...getInputProps()} />

        <Placeholder
          sx={{
            ...(hasFile && {
              opacity: 0,
            }),
          }}
        />

        {hasFile && <SingleFilePreview file={file} />}
      </StyledDropZone>

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />

      {hasFiles && (
        <>
          <Box sx={{ my: 3 }}>
            <MultiFilePreviewTable files={files} onRemove={onRemove} />
          </Box>

          {!autoUpload && ( // Show upload button only if not auto-upload
            <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
              {onRemoveAll && (
                <Button color="inherit" variant="outlined" size="small" onClick={onRemoveAll}>
                  Remove all
                </Button>
              )}

              {onUpload && (
                <Button size="small" variant="contained" onClick={() => onUpload(files)}>
                  Upload files
                </Button>
              )}
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}

// Placeholder component
Placeholder.propTypes = {
  sx: PropTypes.object,
};

function Placeholder({ sx, ...other }) {
  return (
    <Stack
      spacing={5}
      alignItems="center"
      justifyContent="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        width: 1,
        textAlign: {
          xs: 'center',
          md: 'left',
        },
        ...sx,
      }}
      {...other}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <div>
        <Typography gutterBottom variant="h5">
          Drop or Select file
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Drop files here or click
          <Typography
            variant="body2"
            component="span"
            sx={{
              mx: 0.5,
              color: 'primary.main',
              textDecoration: 'underline',
            }}
          >
            browse
          </Typography>
          through your machine
        </Typography>
      </div>
    </Stack>
  );
}
