import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from 'utils/error';

const name = 'ListOfSurgical';
 
const depthPath = 'managements.';

const apis = {};

const initialState = {
    data: [],
    loading: false,
    editLoading: false,
    createLoading: false,
    deleteLoading: false,
    currentData: null,
    openDialog: {
      create: false,
      edit: false,
      delete: false,
    },
};

const slice = createSlice({
    name,
    initialState,
    reducers: {
      setData: (state, action) => {
        state.data = action.payload;
      },
      setCurrentData: (state, action) => {
        state.currentData = action.payload;
      },
      setLoading: (state, action) => {
        state.loading = action.payload;
      },
      setEditLoading: (state, action) => {
        state.editLoading = action.payload;
      },
      setCreateLoading: (state, action) => {
        state.createLoading = action.payload;
      },
      setDeleteLoading: (state, action) => {
        state.deleteLoading = action.payload;
      },
      setOpenDialog: (state, action) => {
        const { target, value } = action.payload;
        state.openDialog[target] = value;
      },
      setError: (state, action) => {
        showError(action.payload);
      },
    },
});

const promiseActions = {};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
