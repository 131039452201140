import { combineReducers } from 'redux';
import appointmentSlice from './appointment';
import patientListSlice from './patient-list';
import visitListSlice from './visit-list';
import listBillSlice from './list-bill';
import doctorListSlice from './doctor-list';
import nurseListSlice from './nurse-list';
import staffSlice from './staff';
import orderListSlice from './order-list-v2';
import medicationSlice from './medication';
import precriptionListSlice from './prescription-list';
import listFeeServicePackageSlice from './list-fee-service-packages';
import customerListSlice from './customer-list';
import billingListSlice from './billing-list';
import serviceTypeSlice from './service-type';
import newRegistration from './new-registration';
import userListSlice from './user-list';
import accessModuleSlice from './access-module';
import stockListSlice from './stock-list';
import patientMedicalPackageSlice from './patient-medical-package';
import laborTestTempletSlice from './labor-test-templet';
import laborTestResultSlice from './labor-test-request';
import laborTestRequestSlice from './labor-test-result';
import investigationRequestSlice from './investigation-request';
import investigationResultSlice from './investigation-result';
import ipd from './ipd';
import billsListSlice from './bills-list';
import employeeListSlice from './to-visit-from-patient-list';
import progressNoteSlice from './progress-note';
import roomManagementSlice from './room-management';
import dischargeDoctorSlide from './discharge_doctor';
import laboratory from './warning_investigation';
import visitServiceType from './visit-service';
import visitProcedure from './visit_procedure';
import visitTreatment from './visit_treatment';
import telegramApp from './investigation-socket-app';
import investigationType from './investigation-type';
import visitOperation from './visit-operation';
import vaccination from './vaccination';
import laborResultOverlay from './labor-result-overlay';
import patientHistory from './patieint_history';
import guest from './guest';
import patientPrintHistory from './patient-print';
import voice from './voice';
import globalResultInvestigation from './global-result';
import investigationSocketIpd from './investigation-socket-ipd';
import medicalEquipmentSlice from './medical-equipment';
import financial from './financial';
import patientLaboratoryTestRequestSlice from './patient-laboratory-test-request';
import expenseListSlice from './expense';
import incomeListSlice from './income';
import issuedPrescription from './issued-prescription';
import dischargeSummary from './discharge-summary';
import supplierList from './supplier-list';
import departmentList from './department-list';
import patientRefund from './patient-refund';
import patientInsuranceClaimSlice from './patient-insurance-claim';
import balanceSheetList from './balance-sheet-list';
import financialDeposit from './financial-deposit';
import insuranceCliam from './insurance-cliam';
import financialCashFlow from './financial-cash-flow';
import customerBalanceSheet from './customer_balance_sheet';
import discountReport from './discount_report';
import medicationReportSlices from './medication-report';
import depositReport from './deposit_report';
import ipd_treatment from './ipd_treatment';
import surveillanceIPD from './surveillance_ipd';
import wardRoomBed from './ward-room-bed';
import bed_ipd from './bed_ipd';
import wardRoomManagement from './ward-room-management';
import letterTemplateListSlice from './letter-template';
import insuranceList from './insurance-list';
import laboratoryTestTemplate from './laboratory-test-template';
import doctorSession from './doctor-session';
import equipmentInventoryReportSlices from './equipment-inventory-report';
import patientReportSlices from './patient-report';
import surgicalTeam from './surgicalTeam';
import surgicalPatient from './surgical-patient';
import surgicalRoom from './surgical-room';
import surgery from './surgery';
import ListOfSurgical from './list-of-surgical';

export default combineReducers({
  ...patientLaboratoryTestRequestSlice.reducer,
  ...laboratoryTestTemplate.reducer,
  ...appointmentSlice.reducer,
  ...patientListSlice.reducer,
  ...medicationReportSlices.reducer,
  ...equipmentInventoryReportSlices.reducer,
  ...visitListSlice.reducer,
  ...listBillSlice.reducer,
  ...doctorListSlice.reducer,
  ...nurseListSlice.reducer,
  ...staffSlice.reducer,
  ...orderListSlice.reducer,
  ...medicationSlice.reducer,
  ...precriptionListSlice.reducer,
  ...listFeeServicePackageSlice.reducer,
  ...customerListSlice.reducer,
  ...billingListSlice.reducer,
  ...serviceTypeSlice.reducer,
  ...newRegistration.reducer,
  ...userListSlice.reducer,
  ...accessModuleSlice.reducer,
  ...stockListSlice.reducer,
  ...patientMedicalPackageSlice.reducer,
  ...laborTestTempletSlice.reducer,
  ...laborTestRequestSlice.reducer,
  ...laborTestResultSlice.reducer,
  ...investigationRequestSlice.reducer,
  ...investigationResultSlice.reducer,
  ...ipd.reducer,
  ...expenseListSlice.reducer,
  ...incomeListSlice.reducer,
  ...billsListSlice.reducer,
  ...incomeListSlice.reducer,
  ...employeeListSlice.reducer,
  ...progressNoteSlice.reducer,
  ...roomManagementSlice.reducer,
  ...dischargeDoctorSlide.reducer,
  ...laboratory.reducer,
  ...visitServiceType.reducer,
  ...visitProcedure.reducer,
  ...visitTreatment.reducer,
  ...telegramApp.reducer,
  ...investigationType.reducer,
  ...visitOperation.reducer,
  ...vaccination.reducer,
  ...laborResultOverlay.reducer,
  ...patientHistory.reducer,
  ...guest.reducer,
  ...patientPrintHistory.reducer,
  ...voice.reducer,
  ...globalResultInvestigation.reducer,
  ...investigationSocketIpd.reducer,
  ...medicalEquipmentSlice.reducer,
  ...financial.reducer,
  ...issuedPrescription.reducer,
  ...dischargeSummary.reducer,
  ...supplierList.reducer,
  ...departmentList.reducer,
  ...patientRefund.reducer,
  ...patientInsuranceClaimSlice.reducer,
  ...financialDeposit.reducer,
  ...balanceSheetList.reducer,
  ...insuranceCliam.reducer,
  ...financialCashFlow.reducer,
  ...customerBalanceSheet.reducer,
  ...discountReport.reducer,
  ...depositReport.reducer,
  ...ipd_treatment.reducer,
  ...surveillanceIPD.reducer,
  ...wardRoomBed.reducer,
  ...bed_ipd.reducer,
  ...wardRoomManagement.reducer,
  ...letterTemplateListSlice.reducer,
  ...insuranceList.reducer,
  ...doctorSession.reducer,
  ...patientReportSlices.reducer,
  ...surgicalTeam.reducer,
  ...surgicalPatient.reducer,
  ...surgicalRoom.reducer,
  ...surgery.reducer,
  ...ListOfSurgical.reducer,
});
