import axios from 'axios';

import { get } from 'lodash';

import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { showError } from 'utils/error';

import { format } from 'date-fns';

const name = 'surgery';

const depthPath = 'managements.';

const apis = {};

const initialState = {
  data: [],
  loading: false,
  openCreateDialog: false,
  currentEditData: null,
  currentDeleteData: null,
  calendarView: 'month',
  calendarViewDate: new Date(),
  calendarViewDays: [],
  calendarData: [],
  calendarLoading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    setCurrentDeleteData: (state, action) => {
      state.currentDeleteData = action.payload;
    },
    setCalendarView: (state, action) => {
      state.calendarView = action.payload;
    },
    setCalendarViewDate: (state, action) => {
      state.calendarViewDate = action.payload;
    },
    setCalendarViewDays: (state, action) => {
      state.calendarViewDays = action.payload;
    },
    setCalendarLoading: (state, action) => {
      state.calendarLoading = action.payload;
    },
    pushNewAppointment: (state, action) => {
      // const item = action.payload;
      // console.log('item:', item);
      // const newItem = {
      //   ...item,
      //   date: item?.appointment_date,
      //   day: format(new Date(item?.appointment_date), 'dd'),
      // };
      // state.calendarData = [...state.calendarData, newItem];
    },
    setCalendarData: (state, action) => {
      // const data = action.payload;
      // const fData = data?.map((item) => ({
      //   ...item,
      //   date: item?.appointment_date,
      //   day: format(new Date(item?.appointment_date), 'dd'),
      // }));
      // state.calendarData = fData;
    },
  },
});

const promiseActions = {};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
